import React from 'react';

const IconPoketoWordmark = props => (
  <svg viewBox="0 0 90 24" width="1em" height="1em" {...props}>
    <path
      d="M7 16.256V7.424c0-.688.544-1.232 1.232-1.232h3.344c2.672 0 4.288 1.584 4.288 3.872v.032c0 2.592-2.016 3.936-4.528 3.936H9.464v2.224c0 .688-.544 1.232-1.232 1.232S7 16.944 7 16.256zm2.464-4.416h1.952c1.232 0 1.952-.736 1.952-1.696v-.032c0-1.104-.768-1.696-2-1.696H9.464zm15.164 5.744c-3.456 0-5.936-2.576-5.936-5.76v-.032C18.692 8.608 21.204 6 24.66 6s5.936 2.576 5.936 5.76v.032c0 3.184-2.512 5.792-5.968 5.792zm.032-2.272c1.984 0 3.36-1.568 3.36-3.488v-.032c0-1.92-1.408-3.52-3.392-3.52s-3.36 1.568-3.36 3.488v.032c0 1.92 1.408 3.52 3.392 3.52zm9.628.944V7.328c0-.688.544-1.232 1.232-1.232s1.232.544 1.232 1.232v3.76l4.544-4.512c.304-.304.608-.48 1.04-.48.688 0 1.136.528 1.136 1.136 0 .384-.176.672-.448.928l-3.152 2.944 3.44 4.32c.192.24.336.496.336.864 0 .688-.528 1.2-1.248 1.2-.48 0-.768-.24-1.04-.592l-3.2-4.192-1.408 1.312v2.24c0 .688-.544 1.232-1.232 1.232s-1.232-.544-1.232-1.232zm14.124 1.136a1.222 1.222 0 0 1-1.232-1.232V7.424c0-.688.544-1.232 1.232-1.232h6.208c.608 0 1.104.496 1.104 1.104s-.496 1.088-1.104 1.088h-4.992v2.272H53.9c.608 0 1.104.496 1.104 1.104s-.496 1.088-1.104 1.088h-4.272V15.2H54.7c.608 0 1.104.496 1.104 1.104s-.496 1.088-1.104 1.088zm13.596-1.136V8.464H59.64c-.624 0-1.136-.512-1.136-1.136s.512-1.136 1.136-1.136h7.2c.624 0 1.136.512 1.136 1.136s-.512 1.136-1.136 1.136h-2.368v7.792c0 .688-.544 1.232-1.232 1.232s-1.232-.544-1.232-1.232zm14.7 1.328c-3.456 0-5.936-2.576-5.936-5.76v-.032C70.772 8.608 73.284 6 76.74 6s5.936 2.576 5.936 5.76v.032c0 3.184-2.512 5.792-5.968 5.792zm.032-2.272c1.984 0 3.36-1.568 3.36-3.488v-.032c0-1.92-1.408-3.52-3.392-3.52s-3.36 1.568-3.36 3.488v.032c0 1.92 1.408 3.52 3.392 3.52z"
      fillRule="evenodd"
    />
  </svg>
);

export default IconPoketoWordmark;
